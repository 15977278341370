import React, { Component, Fragment } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import { BoxArrowRight } from "react-bootstrap-icons";
import auth from "../helpers/auth";

export default class Header extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Col xs={6} md={2}>
            <Image src="/onpoint-logo.png" fluid />
          </Col>
          <Col className="d-none d-sm-block" md={6}></Col>
          <Col
            xs={6}
            md={3}
            className="justify-content-end d-flex align-items-center"
          >
            <Button
              className="RoundedButton"
              onClick={() => {
                auth.logout(() => {
                  this.props.history.push("/");
                });
              }}
            >
              <BoxArrowRight /> Logout
            </Button>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={8} lg={4} className="mx-auto">
            <Image src="/SamsungLogo.png" fluid />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-5 text-center">
            <h1>{this.props.title}</h1>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
