import React, { Component, Fragment } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Header from "./header";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { CircleFill } from "react-bootstrap-icons";
import {
  getBookedAppointment,
  getContractDetails,
  getServiceOrder,
} from "../api/serviceavenger";
import parse from "html-react-parser";
import "./ServiceOrder.css";

export default class ServiceOrder extends Component {
  state = {
    address1: "",
    address2: "",
    appointment: {},
    block: "",
    builder: "",
    city: "",
    contractNumber: "",
    email: "",
    firstName: "",
    lastName: "",
    loading: true,
    lot: "",
    model: "",
    serial: "",
    serviceOrder: {},
    serviceOrderNumber: "",
    state: "",
    warrantyStatus: "",
    zipCode: "",
  };

  componentDidMount = async () => {
    let appointment = {};
    let serviceOrder = {};
    let serviceOrderNumber = "";
    let contractNumber = "";
    let contractDetail = {};
    let productDetail = {};
    let productList = [];

    serviceOrderNumber = await this.getServiceOrderNumber();
    serviceOrder = await this.getServiceOrderDetails(serviceOrderNumber);
    contractNumber = serviceOrder.onPointContractNumber;
    contractDetail = await this.getContractDetails(contractNumber);
    productList = contractDetail.product;
    productDetail = productList?.length > 0 ? productList[0] : {};
    appointment = await this.getBookedAppointment(
      contractNumber,
      serviceOrderNumber
    );

    const {
      address1,
      address2,
      city,
      email,
      firstName,
      lastName,
      onPointContractNumber,
      state,
      zipCode,
    } = serviceOrder;
    const { modelNumber, serial } = productDetail;

    const { generic1, generic2, generic3 } = contractDetail;

    this.setState({
      address1,
      address2,
      appointment,
      block: generic3,
      builder: generic1,
      city,
      contractNumber: onPointContractNumber,
      email,
      firstName,
      lastName,
      loading: false,
      lot: generic2,
      model: modelNumber,
      serial,
      serviceOrder,
      serviceOrderNumber,
      state,
      zipCode,
    });
  };

  getBookedAppointment = async (contractNumber, serviceOrderNumber) => {
    let appointmentResponse = {};
    let appointment = {};
    let errorString = "";

    try {
      if (
        serviceOrderNumber != null &&
        serviceOrderNumber?.length > 0 &&
        contractNumber != null &&
        contractNumber?.length > 0
      ) {
        appointmentResponse = await getBookedAppointment(
          contractNumber,
          serviceOrderNumber
        );
        if (appointmentResponse.status === 200) {
          if (appointmentResponse.data.status !== 200) {
            const { data } = appointmentResponse;
            errorString += `<b>Error while getting service order details:</b><br/>${data?.status} - ${data?.message}`;
            let errorDetails = data.data.details;

            if (errorDetails?.length > 0) {
              errorString += "<ul>";
              errorDetails.forEach((error) => {
                errorString += `<li>${error.code} - ${error.message}</li>`;
              });
              errorString += "</ul>";
            }

            this.setState({ errorMessage: errorString });
            window.location.href = "#errorFound";
            return null;
          } else {
            appointment =
              appointmentResponse.length > 0
                ? appointmentResponse.data.data[0]
                : {};
            this.setState({ appointment });
          }
        }
      } else {
        errorString += `There was an issue getting the appointment details.  Please return to the <a href="/ServiceOrderList">dashboard</a> and try again.<br />`;
      }
    } catch (ex) {
      this.setState({
        errorMessage: (errorString +=
          "Error before getting appointment details: " + ex.message),
      });
      window.location.href = "#errorFound";
      throw ex;
    }
    return appointment;
  };

  getCircleColor(serviceOrder) {
    let color = "";
    const daysSinceClaimCreation =
      (new Date() - new Date(serviceOrder?.claimDateConsumerFiled)) /
      (1000 * 60 * 60 * 24); // 1000 ms/sec * 60 sec/min * 60 min/hour * 24 hours/day
    if (daysSinceClaimCreation >= 0 && daysSinceClaimCreation < 8) {
      color = "green";
    } else if (daysSinceClaimCreation > 7 && daysSinceClaimCreation < 15) {
      color = "orange";
    } else if (daysSinceClaimCreation > 14) {
      color = "red";
    }
    return <CircleFill color={color} />;
  }

  getContractDetails = async (contractNumber) => {
    let errorString = this.state.errorMessage;
    let contractDetails = {};
    let product = {};

    try {
      if (contractNumber.length > 0) {
        contractDetails = await getContractDetails(contractNumber);
      }
    } catch (ex) {
      this.setState({
        errorMessage: (errorString +=
          "Error before getting contract details: " + ex.message),
      });
      window.location.href = "#errorFound";
      throw ex;
    }

    if (contractDetails.status === 200) {
      if (contractDetails.data.status !== 200) {
        const { data } = contractDetails;
        errorString += `<b>Error while getting contract details:</b><br/>${data?.status} - ${data?.message}`;
        let errorDetails = data.data.details;

        if (errorDetails?.length > 0) {
          errorString += "<ul>";
          errorDetails.forEach((error) => {
            errorString += `<li>${error.code} - ${error.message}</li>`;
          });
          errorString += "</ul>";
        }

        this.setState({ errorMessage: errorString });
        window.location.href = "#errorFound";
      } else {
        return contractDetails.data.data.contract;
      }
    }

    return product;
  };

  getServiceOrderDetails = async (serviceOrderNumber) => {
    let serviceOrder = {};
    let errorString = this.state.errorMessage;

    try {
      if (serviceOrderNumber != null && serviceOrderNumber?.length > 0) {
        const serviceOrderResponse = await getServiceOrder(serviceOrderNumber);
        if (serviceOrderResponse.status === 200) {
          if (serviceOrderResponse.data.status !== 200) {
            const { data } = serviceOrderResponse;
            errorString += `<b>Error while getting service order details:</b><br/>${data?.status} - ${data?.message}`;
            let errorDetails = data.data.details;

            if (errorDetails?.length > 0) {
              errorString += "<ul>";
              errorDetails.forEach((error) => {
                errorString += `<li>${error.code} - ${error.message}</li>`;
              });
              errorString += "</ul>";
            }

            this.setState({ errorMessage: errorString });
            window.location.href = "#errorFound";
            return null;
          } else {
            const list = serviceOrderResponse.data.data;
            serviceOrder = list.length > 0 ? list[0] : {};
          }
        }
      } else {
        errorString += `There was an issue getting the service order details.  Please return to the <a href="/ServiceOrderList">dashboard</a> and try again.<br />`;
      }
    } catch (ex) {
      this.setState({
        errorMessage: (errorString +=
          "Error before getting service order details: " + ex.message),
      });
      window.location.href = "#errorFound";
      throw ex;
    }

    return serviceOrder;
  };

  getServiceOrderNumber = async () => {
    let serviceOrderNumber = "";
    let serviceOrderNumberQS = "";
    let errorString = this.state.errorMessage;

    try {
      serviceOrderNumberQS = new URLSearchParams(window.location.search).get(
        "serviceOrderNumber"
      );

      if (serviceOrderNumberQS.length > 0) {
        serviceOrderNumber = serviceOrderNumberQS;
      }
    } catch (ex) {
      this.setState({
        errorMessage: (errorString +=
          "<br /><b>Error before getting service order from the query string</b>: " +
          ex.message),
      });
      window.location.href = "#errorFound";
      throw ex;
    }
    return serviceOrderNumber;
  };

  render() {
    const {
      address1,
      address2,
      appointment,
      block,
      builder,
      city,
      email,
      errorMessage,
      firstName,
      lastName,
      loading,
      lot,
      model,
      serial,
      serviceOrder,
      serviceOrderNumber,
      state,
      zipCode,
    } = this.state;
    const { history } = this.props;

    let serviceOrderNumberDisplay =
      typeof serviceOrderNumber !== "undefined" &&
      serviceOrderNumber?.length > 0
        ? serviceOrderNumber
        : "";

    return (
      <Container className="mt-3 mb-5">
        <Header
          history={history}
          title={`Ticket ${serviceOrderNumberDisplay}`}
        />
        {loading && (
          <Row className="mt-3">
            <Col>
              <Spinner animation="border"></Spinner>
              <br />
              <span className="spinner-text">Loading details...</span>
            </Col>
          </Row>
        )}
        {serviceOrder && !loading && (
          <Fragment>
            <Row>
              <Col xs={12} md={4}>
                <Form.Group>
                  <Form.Label className="bold">Status</Form.Label>
                  <br />
                  {this.getCircleColor(serviceOrder)}
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group>
                  <Form.Label className="bold">Builder</Form.Label>
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue={builder}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group>
                  <Form.Label className="bold">Dispatch</Form.Label>
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue={
                      Object.keys(appointment).length === 0
                        ? "To Be Scheduled"
                        : appointment.appointmentDate
                    }
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={4}>
                <Form.Group>
                  <Form.Label className="bold">Service Address</Form.Label>
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue={`${firstName} ${lastName}`}
                  ></Form.Control>
                  <Form.Text>{address1}</Form.Text>
                  {address2?.length > 0 && (
                    <Form.Text>
                      <br />
                      {address2}
                    </Form.Text>
                  )}
                  <Form.Text>
                    {address2?.length > 0 && <br />}
                    {city}
                    {", "}
                  </Form.Text>
                  <Form.Text>
                    {state}
                    {"  "}
                  </Form.Text>
                  <Form.Text>{zipCode}</Form.Text>
                  {lot?.length > 0 && (
                    <Form.Text>
                      <br />
                      Lot {lot}{" "}
                    </Form.Text>
                  )}
                  {block?.length > 0 && <Form.Text>Block {block}</Form.Text>}
                  {email?.length > 0 && (
                    <Form.Text>
                      <br />
                      {email}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group>
                  <Form.Label className="bold">Parts</Form.Label>
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue=""
                  ></Form.Control>
                </Form.Group>
                {/*<Form.Text>Parts Status will go here.</Form.Text>*/}
              </Col>
              <Col xs={12} md={4}></Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={4}>
                <Form.Group>
                  <Form.Label className="bold">Product</Form.Label>
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue={model}
                  ></Form.Control>
                </Form.Group>
                <Form.Text>{serial}</Form.Text>
              </Col>
              <Col xs={12} md={8}>
                <Form.Group>
                  <Form.Label className="bold">Problem Description</Form.Label>
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue={serviceOrder?.claimStorySymptom}
                  ></Form.Control>
                </Form.Group>
                <Form.Text>{serviceOrder?.claimFailureNote}</Form.Text>
              </Col>
            </Row>
          </Fragment>
        )}
        {errorMessage?.length > 0 && !loading && (
          <Row className="mt-3">
            <Col>
              <Alert id="errorFound" variant="danger">
                {parse(errorMessage)}
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Button
              className="mt-5"
              onClick={() => this.props.history.push("/ServiceOrderList")}
            >
              Return to Dashboard
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}
