import React, { Component, Fragment } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { Camera } from "react-bootstrap-icons";
import {
  createFNOL,
  getFailureTypes,
  getModelBySerial,
  createContract,
} from "../api/serviceavenger";
import parse from "html-react-parser";
import BarcodeScanner from "./BarcodeScanner";
import DBR from "../helpers/dbr";
import toast, { Toaster } from "react-hot-toast";
import Header from "./header";
import "./recall.css";

export default class Recall extends Component {
  state = {
    address: "",
    address2: "",
    block: "",
    brand: "",
    brandCode: "",
    builder: "",
    city: "",
    contractNumber: "",
    decodedResults: [],
    email: "",
    errorMessage: "",
    failureNote: "",
    firstName: "",
    functionalityStatus: "",
    hasAddressSaveFlg: false,
    hasProductSaveFlg: false,
    isAddressValidated: false,
    isAddressValid: null,
    isAddress2Validated: false,
    isAddress2Valid: null,
    isCityValidated: false,
    isCityValid: null,
    isEmailValidated: false,
    isEmailValid: null,
    isFailureNoteValidated: false,
    isFailureNoteValid: null,
    isFirstNameValidated: false,
    isFirstNameValid: null,
    isFunctionalityStatusValidated: false,
    isFunctionalityStatusValid: null,
    isLastNameValidated: false,
    isLastNameValid: null,
    isModelNumberValidated: false,
    isModelNumberValid: null,
    isPrimaryPhoneValidated: false,
    isPrimaryPhoneValid: null,
    isProductTypeValidated: false,
    isProductTypeValid: null,
    isSecondaryPhoneValidated: false,
    isSecondaryPhoneValid: null,
    isSerialNumberValidated: false,
    isSerialNumberValid: null,
    isStateValidated: false,
    isStateValid: null,
    isSymptomCodeValidated: false,
    isSymptomCodeValid: null,
    isZipCodeValidated: false,
    isZipCodeValid: null,
    lastName: "",
    loadingSymptomCodes: false,
    lot: "",
    modelNumber: "",
    modelSearchMessage: "",
    modelSearchWorking: false,
    primaryPhone: "",
    productCode: "",
    productType: "",
    scannerErrorMessage: "",
    secondaryPhone: "",
    serialNumber: "",
    servicerNotes: "",
    showScanner: false,
    showSerialNumberScanModal: false,
    state: "",
    subcategories: [
      {
        subCatCode: "CKTP",
        subCatDescription: "COOKTOP",
        tierCode: "COOK",
      },
      {
        subCatCode: "DW",
        subCatDescription: "DISHWASHER",
        tierCode: "DISH",
      },
      {
        subCatCode: "WASH",
        subCatDescription: "WASHER",
        tierCode: "WASHER",
      },
      {
        subCatCode: "DRY",
        subCatDescription: "DRYER",
        tierCode: "DRYER",
      },
      {
        subCatCode: "MWO",
        subCatDescription: "MICROWAVE",
        tierCode: "MWO",
      },
      {
        subCatCode: "OVEN",
        subCatDescription: "WALL OVEN",
        tierCode: "OVEN",
      },
      {
        subCatCode: "REFRIG",
        subCatDescription: "REFRIGERATOR",
        tierCode: "REFRIG",
      },
      {
        subCatCode: "RGHD",
        subCatDescription: "RANGE HOODS",
        tierCode: "RANGE",
      },
      {
        subCatCode: "RNG",
        subCatDescription: "RANGE",
        tierCode: "RANGE",
      },
    ],
    submitWorking: false,
    symptomCode: "",
    symptomCodeList: [],
    tierCode: "",
    zipcode: "",

    hasScannerLibraryLoaded: false,
  };

  componentDidMount = async () => {
    try {
      await DBR.BarcodeScanner.loadWasm();
      this.setState(
        (state) => {
          state.hasScannerLibraryLoaded = true;
          return state;
        },
        () => {
          this.setState({ showScanner: true });
        }
      );
    } catch (ex) {
      alert(ex.message);
      throw ex;
    }
  };

  fillSerialNumber = (message) => {
    switch (message.type) {
      case "result":
        this.setState({
          serialNumber: message.text,
          showScanner: false,
          showSerialNumberScanModal: false,
        });
        break;
      case "error":
        this.setState({ scannerErrorMessage: message.msg });
        break;
      default:
        break;
    }
  };

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  validatePhone(phone) {
    const cleanString = phone.replace(/\D/g, "");
    return cleanString?.length === 10;
  }

  validateSerialNumber = () => {
    this.resetValidation();

    const { serialNumber } = this.state;

    this.setState({
      isSerialNumberValidated: true,
      isSerialNumberValid: serialNumber?.trim().length === 15 ? true : false,
    });

    return serialNumber?.trim().length === 15;
  };

  validatePage = () => {
    const {
      address,
      city,
      email,
      failureNote,
      firstName,
      functionalityStatus,
      lastName,
      modelNumber,
      primaryPhone,
      productType,
      secondaryPhone,
      serialNumber,
      symptomCode,
      state,
      zipcode,
    } = this.state;

    this.setState({
      isAddressValidated: true,
      isAddressValid: address?.trim().length === 0 ? false : true,
      isCityValidated: true,
      isCityValid: city?.trim().length === 0 ? false : true,
      isEmailValidated: true,
      isEmailValid:
        email?.trim().length === 0 ? false : this.validateEmail(email?.trim()),
      isFailureNoteValidated: true,
      isFailureNoteValid: failureNote?.trim().length > 0 ? true : false,
      isFirstNameValidated: true,
      isFirstNameValid: firstName?.trim().length === 0 ? false : true,
      isFunctionalityStatusValid:
        functionalityStatus?.length === 0 ? false : true,
      isFunctionalityStatusValidated: true,
      isLastNameValidated: true,
      isLastNameValid: lastName?.trim().length === 0 ? false : true,
      isModelNumberValidated: true,
      isModelNumberValid: modelNumber?.trim().length > 0 ? true : false,
      isPrimaryPhoneValidated: true,
      isPrimaryPhoneValid:
        primaryPhone?.trim().length === 0
          ? false
          : this.validatePhone(primaryPhone?.trim()),
      isProductTypeValid: productType?.length === 0 ? false : true,
      isProductTypeValidated: true,
      isSecondaryPhoneValidated:
        secondaryPhone?.trim().length > 0 ? true : false,
      isSecondaryPhoneValid:
        secondaryPhone?.trim().length > 0
          ? this.validatePhone(secondaryPhone.trim())
          : null,
      isSerialNumberValidated: true,
      isSerialNumberValid: serialNumber?.trim().length === 15 ? true : false,
      isStateValidated: true,
      isStateValid: state?.length === 0 ? false : true,
      isSymptomCodeValidated: true,
      isSymptomCodeValid: symptomCode?.trim().length > 0 ? true : false,
      isZipCodeValidated: true,
      isZipCodeValid: zipcode?.trim().length === 5 ? true : false,
    });

    return (
      address?.trim().length > 0 &&
      city?.trim().length > 0 &&
      email?.trim().length > 0 &&
      this.validateEmail(email?.trim()) &&
      failureNote?.trim().length > 0 &&
      firstName?.trim().length > 0 &&
      functionalityStatus?.length > 0 &&
      lastName?.trim().length > 0 &&
      modelNumber?.trim().length > 0 &&
      primaryPhone?.trim().length > 0 &&
      productType?.length > 0 &&
      this.validatePhone(primaryPhone) &&
      (secondaryPhone?.trim().length === 0 ||
        (secondaryPhone?.trim().length > 0 &&
          this.validatePhone(secondaryPhone))) &&
      serialNumber?.trim().length === 15 &&
      symptomCode?.trim().length > 0 &&
      state?.trim().length > 0 &&
      zipcode?.trim().length === 5
    );
  };

  resetValidation = () => {
    this.setState({
      isAddressValidated: false,
      isAddressValid: null,
      isCityValidated: false,
      isCityValid: null,
      isEmailValidated: false,
      isEmailValid: null,
      isFailureNoteValidated: false,
      isFailureNoteValid: null,
      isFirstNameValidated: false,
      isFirstNameValid: null,
      isFunctionalityStatusValid: null,
      isFunctionalityStatusValidated: false,
      isLastNameValidated: false,
      isLastNameValid: null,
      isModelNumberValidated: false,
      isModelNumberVAlid: null,
      isPrimaryPhoneValidated: false,
      isPrimaryPhoneValid: null,
      isProductTypeValid: null,
      isProductTypeValidated: false,
      isSecondaryPhoneValidated: false,
      isSecondaryPhoneValid: null,
      isSerialNumberValidated: false,
      isSerialNumberValid: null,
      isStateValidated: false,
      isStateValid: null,
      isSymptomCodeValidated: false,
      isSymptomCodeValid: null,
      isZipCodeValidated: false,
      isZipCodeValid: null,
    });
  };

  getModelBySerial = async (serialNumber) => {
    try {
      let errorMessage = "";
      this.setState({
        errorMessage: "",
        modelSearchMessage: "",
        modelSearchWorking: true,
      });
      const response = await getModelBySerial(serialNumber);
      const { status, data, message } = response.data;

      if (status === 200) {
        if (data.returnCode.toString() === "0") {
          if (data.modelNumber.length > 0) {
            this.setState({
              modelSearchMessage: "",
              modelNumber: data.modelNumber,
              modelSearchWorking: false,
            });
          } else {
            this.setState({
              modelSearchMessage: `No model number was returned for serial number ${serialNumber}.`,
              modelSearchWorking: false,
            });
            setTimeout(() => {
              this.setState({ modelSearchMessage: "" });
            }, 5000);
          }
        }
        if (data.returnCode.toString() === "1") {
          this.setState({
            modelNumber: "",
            modelSearchMessage: `No model number was returned for serial number ${serialNumber}.`,
            modelSearchWorking: false,
          });
          setTimeout(() => {
            this.setState({ modelSearchMessage: "" });
          }, 5000);
        }
        if (
          data.returnCode.toString() !== "0" &&
          data.returnCode.toString() !== "1"
        ) {
          errorMessage += `<br/><b>Error while getting model number:</b><br/>Return code: ${data.returnCode} - ${data.errorMessage}`;
          errorMessage += `<br/>Attempted to search for serial number: ${serialNumber}.`;
          this.setState({
            errorMessage,
            modelNumber: "",
            modelSearchWorking: false,
          });
          window.location.href = "#errorFound";
        }
      } else {
        errorMessage += `<br/><b>Error while getting model number:</b><br/>${status}<br/>${message}`;
        this.setState({ errorMessage, modelSearchWorking: false });
        window.location.href = "#errorFound";
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error while getting model number:</b><br/>${error.message}`,
        modelSearchWorking: false,
      });
      window.location.href = "#errorFound";
    }
  };

  getSymptomCodeList = async (productType) => {
    try {
      this.setState({ loadingSymptomCodes: true });
      const { subcategories } = this.state;

      let errorMessage = this.state.errorMessage;
      const category = subcategories.find(function (category) {
        return (
          category.subCatDescription?.toUpperCase() ===
          productType?.toUpperCase()
        );
      });

      if (category != null) {
        if (category?.tierCode != null && category?.tierCode !== "") {
          const { tierCode } = category;
          const response = await getFailureTypes(tierCode);
          const { status, data } = response;

          if (status === 200) {
            if (data.status !== 200) {
              errorMessage += `<br/><b>Error while getting failure types:</b><br/>${data?.data?.code} - ${data?.data?.message}`;
              errorMessage += `<br/>Attempted to search for tier code ${tierCode}.`;
              let errorDetails = data.data.details;

              if (errorDetails?.length > 0) {
                errorMessage += "<ul>";
                errorDetails.forEach((error) => {
                  errorMessage += `<li>${error.code} - ${error.message}</li>`;
                });
                errorMessage += "</ul>";
              }

              this.setState({ errorMessage, loadingSymptomCodes: false });
              window.location.href = "#errorFound";
            } else {
              const list = data.data.failures;
              this.setState({
                symptomCode: "",
                symptomCodeList: list.length > 0 ? list : [],
                loadingSymptomCodes: false,
              });
            }
          } else {
            errorMessage += `<br/><b>Error while getting failure types:</b><br/>${status}`;
            this.setState({
              errorMessage,
              loadingSymptomCodes: false,
              symptomCode: "",
            });
            window.location.href = "#errorFound";
          }
        }
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error while getting failure types:</b><br/>${error.message}`,
        loadingSymptomCodes: false,
      });
      window.location.href = "#errorFound";
    }
  };

  handleSubmitClick = async () => {
    this.setState({ errorMessage: "", scannerErrorMessage: "" });
    if (this.validatePage()) {
      this.setState({ submitWorking: true });
      let message = "";
      const datetime = new Date();
      const contractNumber = await this.contractCreate(datetime);

      if (contractNumber) {
        const claimNumber = await this.FNOLCreate(contractNumber, datetime);
        if (claimNumber) {
          message += `Ticket ${claimNumber} created.`;
          toast.success(parse(message), {
            duration: 10000,
          });
          this.handleAddressReset();
          this.handleProductReset();
          this.resetValidation();
        }
      }
      this.setState({ submitWorking: false });
    }
  };

  handleAddressReset = async () => {
    if (!this.state.hasAddressSaveFlg) {
      this.setState({
        address: "",
        address2: "",
        block: "",
        builder: "",
        city: "",
        firstName: "",
        lastName: "",
        lot: "",
        primaryPhone: "",
        secondaryPhone: "",
        state: "",
        zipcode: "",
      });
    }
  };

  handleProductReset = async () => {
    if (!this.state.hasProductSaveFlg) {
      this.setState({
        failureNote: "",
        functionalityStatus: "",
        productType: "",
        secondaryPhone: "",
        servicerNotes: "",
        symptomCode: "",
        symptomCodeList: [],
      });
    }
    this.setState({ modelNumber: "", serialNumber: "" });
  };

  getFailureTypeList = (productType) => {
    const subCategory = this.state.subcategories.find(function (subcategory) {
      return (
        subcategory.subCatDescription.toUpperCase() ===
        productType.toUpperCase()
      );
    });
    this.getSymptomCodeList(productType);
    this.setState({
      productType: productType,
      productCode: subCategory.subCatCode,
      tierCode: subCategory.tierCode,
    });
  };

  handleInputChange(event) {
    event.preventDefault();
    this.resetValidation();

    const { target } = event;
    const { id, value } = target;
    switch (id) {
      case "primaryPhone":
        this.setState({
          [id]: value
            ?.replace(/\D+/g, "")
            .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"),
        });
        break;
      case "secondaryPhone":
        this.setState({
          [id]: value
            ?.replace(/\D+/g, "")
            .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"),
        });
        break;
      case "productType":
        if (value?.length > 0) {
          this.getFailureTypeList(value);
        } else {
          this.setState({
            symptomCodeList: [],
          });
        }
        this.setState({
          [id]: value,
          symptomCode: "",
        });
        break;
      default:
        this.setState({ [id]: value });
    }
  }

  barcodeScannerHandleUpdate = (error, result) => {
    if (result) {
      this.setState({
        scannerErrorMessage: "",
        serialNumber: result.text,
        showScanner: false,
        showSerialNumberScanModal: false,
      });
    }
  };

  barcodeScannerHandleError = (error) => {
    if (error.name === "NotAllowedError") {
      this.setState({
        scannerErrorMessage:
          "Camera access has been denied by the user of the device.",
      });
    }
  };

  contractCreate = async (datetime) => {
    const {
      address,
      address2,
      block,
      builder,
      city,
      email,
      firstName,
      lastName,
      lot,
      modelNumber,
      primaryPhone,
      productType,
      secondaryPhone,
      serialNumber,
      state,
      tierCode,
      zipcode,
    } = this.state;

    let errorMessage = "";

    try {
      const response = await createContract(
        address?.trim(),
        address2?.length > 0 ? address2?.trim() : address2,
        block?.trim(),
        builder?.trim(),
        primaryPhone,
        city?.trim(),
        datetime,
        email?.trim(),
        firstName?.trim(),
        lastName?.trim(),
        lot?.trim(),
        modelNumber?.trim(),
        secondaryPhone,
        productType,
        serialNumber?.trim(),
        state,
        tierCode,
        zipcode?.trim()
      );

      const { status, data, message } = response;

      if (status === 200) {
        if (data?.status !== 200) {
          errorMessage += `<b>Error while creating contract:</b><br/>${data?.status} - ${data?.message}`;

          let errorDetails = data?.data?.details;

          if (errorDetails?.length > 0) {
            errorMessage += "<ul>";
            errorDetails?.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
            });
            errorMessage += "</ul>";
          }

          this.setState({ errorMessage });
          window.location.href = "#errorFound";
          return null;
        } else {
          const dataObject = data?.data;
          let contractNumber = Array.isArray(dataObject)
            ? data?.data[0].serviceAvengerContract
            : data?.opwContractID;
          return contractNumber;
        }
      } else {
        errorMessage += `<b>Error while creating contract:</b><br/> ${status} - ${message}`;
        this.setState({ errorMessage });
        window.location.href = "#errorFound";
        return null;
      }
    } catch (error) {
      this.setState({
        errorMessage: `<b>Error while creating contract:</b><br/>${error.message}`,
      });
      window.location.href = "#errorFound";
    }
  };

  FNOLCreate = async (contractNumber, datetime) => {
    let errorMessage = "";

    try {
      const {
        address,
        address2,
        primaryPhone,
        city,
        email,
        failureNote,
        firstName,
        functionalityStatus,
        lastName,
        serialNumber,
        servicerNotes,
        state,
        symptomCode,
        zipcode,
      } = this.state;

      let functionalStatus;

      switch (functionalityStatus) {
        case "Functional":
          functionalStatus = "F";
          break;
        case "Non-functional":
          functionalStatus = "N";
          break;
        default:
          functionalStatus = "";
      }

      const response = await createFNOL(
        address,
        address2,
        city,
        failureNote,
        functionalStatus,
        symptomCode,
        contractNumber,
        datetime,
        email,
        firstName,
        lastName,
        primaryPhone,
        serialNumber,
        servicerNotes,
        state,
        zipcode
      );

      const { status, data, message } = response;

      if (status === 200) {
        if (data?.status !== 200) {
          errorMessage += `<b>Error while creating FNOL:</b><br/>${data?.status} - ${data?.message}`;

          let errorDetails = data?.data?.details;

          if (errorDetails?.length > 0) {
            errorMessage += "<ul>";
            errorDetails?.forEach((error) => {
              errorMessage += `<li>${error?.code} - ${error?.message}</li>`;
            });
            errorMessage += "</ul>";
          }

          this.setState({ errorMessage });
          window.location.href = "#errorFound";
          return null;
        } else {
          const claimNumber = data?.data.claimNumber;
          this.setState({ contractNumber, claimNumber });
          return claimNumber;
        }
      } else {
        errorMessage = this.state.errorMessage;
        errorMessage += `<br/><b>Error while creating FNOL:</b><br/> ${status} - ${message}`;
        this.setState({ errorMessage });
        window.location.href = "#errorFound";
        return null;
      }
    } catch (error) {
      this.setState({
        errorMessage: `<br/><b>Error while creating FNOL:</b><br/>${error.message}`,
      });
      window.location.href = "#errorFound";
    }
  };

  render() {
    const states = [
      "AL",
      "AK",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "DC",
      "FL",
      "GA",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "OH",
      "OK",
      "OR",
      "PA",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY",
    ];

    const {
      address,
      address2,
      block,
      builder,
      city,
      email,
      errorMessage,
      failureNote,
      firstName,
      functionalityStatus,
      hasAddressSaveFlg,
      hasProductSaveFlg,
      hasScannerLibraryLoaded,
      isAddressValidated,
      isAddressValid,
      isCityValidated,
      isCityValid,
      isEmailValid,
      isEmailValidated,
      isFailureNoteValidated,
      isFailureNoteValid,
      isFirstNameValidated,
      isFirstNameValid,
      isFunctionalityStatusValid,
      isFunctionalityStatusValidated,
      isLastNameValidated,
      isLastNameValid,
      isModelNumberValidated,
      isModelNumberValid,
      isPrimaryPhoneValidated,
      isPrimaryPhoneValid,
      isProductTypeValidated,
      isProductTypeValid,
      isSecondaryPhoneValidated,
      isSecondaryPhoneValid,
      isSerialNumberValidated,
      isSerialNumberValid,
      isStateValidated,
      isStateValid,
      isSymptomCodeValidated,
      isSymptomCodeValid,
      isZipCodeValidated,
      isZipCodeValid,
      lastName,
      loadingSymptomCodes,
      lot,
      modelNumber,
      modelSearchMessage,
      modelSearchWorking,
      primaryPhone,
      productType,
      scannerErrorMessage,
      secondaryPhone,
      serialNumber,
      servicerNotes,
      showScanner,
      showSerialNumberScanModal,
      state,
      submitWorking,
      symptomCode,
      symptomCodeList,
      zipcode,
    } = this.state;

    const functionalityStatusList = [
      { statusShown: "Yes", functionalityStatus: "Functional" },
      { statusShown: "No", functionalityStatus: "Non-functional" },
    ];

    const productTypeList = [
      "Cooktop",
      "Dishwasher",
      "Dryer",
      "Microwave",
      "Range",
      "Range Hoods",
      "Refrigerator",
      "Wall Oven",
      "Washer",
    ];

    return (
      <Form>
        <Toaster
          position="top-left"
          toastOptions={{
            success: {
              style: {
                background: "green",
                color: "white",
              },
            },
            error: {
              style: {
                background: "red",
                color: "white",
              },
            },
          }}
        />
        <Container className="mt-3 mb-5">
          <Header history={this.props.history} title="Request Service" />
          <Row>
            <h3 className="mb-3">Builder</h3>
          </Row>
          <Row>
            <Form.Group xs={12} as={Col} controlId="builder" className="mb-3">
              <Form.Label>Builder Name</Form.Label>
              <Form.Control
                autoComplete="none"
                type="text"
                placeholder="Builder"
                value={builder}
                onChange={(event) => {
                  this.handleInputChange(event);
                }}
              ></Form.Control>
            </Form.Group>
          </Row>
          <Row>
            <hr className="mt-5 mb-5" />
          </Row>
          <Row>
            <h3 className="mb-3">
              Service Details{" "}
              <small className="ps-5 text-muted">
                Where will the service be performed?
              </small>
            </h3>
          </Row>
          <Row>
            <Col md={12} className="mb-5">
              <Row>
                <Form.Group as={Col} controlId="firstName" className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    required
                    autoComplete="none"
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(event) => {
                      this.handleInputChange(event);
                    }}
                    className={
                      !isFirstNameValidated
                        ? null
                        : isFirstNameValid
                        ? "is-valid"
                        : "is-invalid"
                    }
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please provide a first name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="lastName" className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    required
                    autoComplete="none"
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(event) => {
                      this.handleInputChange(event);
                    }}
                    className={
                      !isLastNameValidated
                        ? null
                        : isLastNameValid
                        ? "is-valid"
                        : "is-invalid"
                    }
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please provide a last name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="address" className="mb-3">
                  <Form.Label>Address of Service</Form.Label>
                  <Form.Control
                    required
                    autoComplete="none"
                    type="text"
                    placeholder="Street Address"
                    value={address}
                    onChange={(event) => {
                      this.handleInputChange(event);
                    }}
                    className={
                      !isAddressValidated
                        ? null
                        : isAddressValid
                        ? "is-valid"
                        : "is-invalid"
                    }
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please provide an address.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  xs={12}
                  md={4}
                  as={Col}
                  controlId="address2"
                  className="mb-3"
                >
                  <Form.Label>Address 2 / Unit # / Apt #</Form.Label>
                  <Form.Control
                    autoComplete="none"
                    type="text"
                    placeholder="Additional Address"
                    value={address2}
                    onChange={(event) => {
                      this.handleInputChange(event);
                    }}
                  ></Form.Control>
                </Form.Group>
                <Form.Group
                  xs={12}
                  md={4}
                  as={Col}
                  controlId="lot"
                  className="mb-3"
                >
                  <Form.Label>Lot</Form.Label>
                  <Form.Control
                    maxLength={30}
                    autoComplete="none"
                    type="text"
                    placeholder="For Builder Reference"
                    value={lot}
                    onChange={(event) => {
                      this.handleInputChange(event);
                    }}
                  ></Form.Control>
                </Form.Group>
                <Form.Group
                  xs={12}
                  md={4}
                  as={Col}
                  controlId="block"
                  className="mb-3"
                >
                  <Form.Label>Block</Form.Label>
                  <Form.Control
                    maxLength={30}
                    autoComplete="none"
                    type="text"
                    placeholder="For Builder Reference"
                    value={block}
                    onChange={(event) => {
                      this.handleInputChange(event);
                    }}
                  ></Form.Control>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  xs={12}
                  md={4}
                  as={Col}
                  controlId="city"
                  className="mb-3"
                >
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    required
                    autoComplete="none"
                    type="text"
                    placeholder="City"
                    value={city}
                    onChange={(event) => {
                      this.handleInputChange(event);
                    }}
                    className={
                      !isCityValidated
                        ? null
                        : isCityValid
                        ? "is-valid"
                        : "is-invalid"
                    }
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please provide a city.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="state" className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    placeholder="State"
                    value={state}
                    onChange={(event) => {
                      this.handleInputChange(event);
                    }}
                    className={
                      !isStateValidated
                        ? null
                        : isStateValid
                        ? "is-valid"
                        : "is-invalid"
                    }
                  >
                    <option key="" value="">
                      Select state...
                    </option>
                    {states.map((state, i) => (
                      <option key={i} value={state}>
                        {state}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select a state.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="zipcode" className="mb-3">
                  <Form.Label>Postal Code</Form.Label>
                  <Form.Control
                    maxLength={5}
                    required
                    autoComplete="none"
                    type="text"
                    placeholder="Postal Code"
                    value={zipcode}
                    onChange={(event) => {
                      this.handleInputChange(event);
                    }}
                    className={
                      !isZipCodeValidated
                        ? null
                        : isZipCodeValid
                        ? "is-valid"
                        : "is-invalid"
                    }
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please provide a 5-digit postal code.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="primaryPhone" className="mb-3">
                  <Form.Label>Primary Phone</Form.Label>
                  <Form.Control
                    required
                    autoComplete="none"
                    maxLength={14}
                    type="text"
                    placeholder="Primary Phone"
                    value={primaryPhone}
                    onChange={(event) => {
                      this.handleInputChange(event);
                    }}
                    className={
                      !isPrimaryPhoneValidated
                        ? null
                        : isPrimaryPhoneValid
                        ? "is-valid"
                        : "is-invalid"
                    }
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid phone number.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="secondaryPhone">
                  <Form.Label>Secondary Phone / Homeowner Number</Form.Label>
                  <Form.Control
                    maxLength={14}
                    autoComplete="none"
                    type="text"
                    placeholder="Secondary Phone"
                    value={secondaryPhone}
                    onChange={(event) => {
                      this.handleInputChange(event);
                    }}
                    className={
                      !isSecondaryPhoneValidated
                        ? "mb-3"
                        : isSecondaryPhoneValid
                        ? "is-valid mb-3"
                        : "is-invalid mb-3"
                    }
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid phone number.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="email" className="mb-3">
                    <Form.Label>
                      Email{" "}
                      <small className="ps-5 text-muted">
                        Address for the site contact
                      </small>
                    </Form.Label>
                    <Form.Control
                      required
                      autoComplete="none"
                      type="text"
                      placeholder="test@test.com"
                      value={email}
                      onChange={(event) => {
                        this.handleInputChange(event);
                      }}
                      className={
                        !isEmailValidated
                          ? "mb-3"
                          : isEmailValid
                          ? "is-valid mb-3"
                          : "is-invalid mb-3"
                      }
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Form.Check
                  type="checkbox"
                  id="hasAddressSaveFlg"
                  label="Remember Address Details"
                  className="ms-3"
                  checked={hasAddressSaveFlg}
                  onChange={() => {
                    this.setState({
                      hasAddressSaveFlg: !this.state.hasAddressSaveFlg,
                    });
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <hr className="mt-5 mb-5" />
          </Row>
          <Row>
            <h3 className="mb-3">Product Details</h3>
          </Row>
          <Row>
            <Col md={12} lg={6} className="mb-3">
              <Row>
                <Form.Group
                  as={Col}
                  controlId="functionalityStatus"
                  className="mb-3"
                >
                  <Form.Label>Does unit Work?</Form.Label>
                  <Form.Control
                    className={
                      !isFunctionalityStatusValidated
                        ? null
                        : isFunctionalityStatusValid
                        ? "is-valid"
                        : "is-invalid"
                    }
                    as="select"
                    value={functionalityStatus}
                    onChange={(event) => {
                      this.handleInputChange(event);
                    }}
                  >
                    <option value="">Select one...</option>
                    {functionalityStatusList.map((functionality) => (
                      <option
                        key={functionality.functionalityStatus}
                        value={functionality.functionalityStatus}
                      >
                        {functionality.statusShown}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select one.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="failureNote" className="mb-3">
                  <Form.Label>Complaint:</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Failure Note"
                    value={failureNote}
                    onChange={(event) => {
                      this.handleInputChange(event);
                    }}
                    className={
                      !isFailureNoteValidated
                        ? null
                        : isFailureNoteValid
                        ? "is-valid"
                        : "is-invalid"
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide the complaint.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="servicerNotes" className="mb-3">
                  <Form.Label>Servicer Notes:</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Ex: Call before arriving so homeowner can kennel dogs."
                    value={servicerNotes}
                    onChange={(event) => {
                      this.handleInputChange(event);
                    }}
                  />
                </Form.Group>
              </Row>
            </Col>
            <Col md={12} lg={6}>
              <Row>
                <Form.Group as={Col} controlId="productType" className="mb-3">
                  <Form.Label>Product Type</Form.Label>
                  <Form.Control
                    className={
                      !isProductTypeValidated
                        ? null
                        : isProductTypeValid
                        ? "is-valid"
                        : "is-invalid"
                    }
                    required
                    as="select"
                    value={productType}
                    onChange={(event) => {
                      this.handleInputChange(event);
                    }}
                  >
                    <option value="">Select product type...</option>
                    {productTypeList.map((productType, i) => (
                      <option key={i} value={productType}>
                        {productType}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select a type.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="symptomCode" className="mb-3">
                  <Form.Label>Symptom Code:</Form.Label>
                  {loadingSymptomCodes && (
                    <React.Fragment>
                      <br />
                      <Spinner animation="border"></Spinner>
                      <br />
                      <span className="spinner-text">
                        Searching for symptom codes...
                      </span>
                    </React.Fragment>
                  )}
                  {!loadingSymptomCodes && (
                    <Form.Control
                      as="select"
                      value={symptomCode}
                      onChange={(event) => {
                        this.handleInputChange(event);
                      }}
                      className={
                        !isSymptomCodeValidated
                          ? null
                          : isSymptomCodeValid
                          ? "is-valid"
                          : "is-invalid"
                      }
                    >
                      <option value="">Select symptom code...</option>
                      {symptomCodeList.map((symptomCode) => (
                        <option
                          key={symptomCode.description}
                          value={symptomCode.description}
                        >
                          {symptomCode.description}
                        </option>
                      ))}
                    </Form.Control>
                  )}
                  <Form.Control.Feedback type="invalid">
                    Please provide a symptom code.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="serialNumber" className="mb-3">
                  <Form.Label>Serial Number</Form.Label>
                  <InputGroup>
                    <Form.Control
                      maxLength={15}
                      required
                      autoComplete="none"
                      type="text"
                      placeholder="Serial Number"
                      value={serialNumber}
                      onChange={(event) => {
                        this.handleInputChange(event);
                      }}
                      className={
                        !isSerialNumberValidated
                          ? null
                          : isSerialNumberValid
                          ? "is-valid"
                          : "is-invalid"
                      }
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      The serial number must be 15 characters.
                    </Form.Control.Feedback>
                    <Button
                      className="serialButton"
                      onClick={() => {
                        this.setState({
                          showSerialNumberScanModal: true,
                          showScanner: true,
                          scannerErrorMessage: "",
                        });
                      }}
                    >
                      <Camera />
                    </Button>
                    <Button
                      className="serialButton"
                      onClick={() => {
                        if (this.validateSerialNumber()) {
                          this.getModelBySerial(serialNumber);
                        }
                      }}
                    >
                      Look Up Model
                    </Button>
                  </InputGroup>
                </Form.Group>
              </Row>
              {modelSearchMessage?.length > 0 ? (
                <Row className="mt-3">
                  <Col>
                    <Alert variant="warning">{parse(modelSearchMessage)}</Alert>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Form.Group as={Col} controlId="modelNumber" className="mb-3">
                  <Form.Label>Model Number</Form.Label>
                  {modelSearchWorking && (
                    <React.Fragment>
                      <br />
                      <Spinner animation="border"></Spinner>
                      <br />
                      <span className="spinner-text">
                        Searching for model...
                      </span>
                    </React.Fragment>
                  )}
                  {!modelSearchWorking && (
                    <Form.Control
                      required
                      type="text"
                      placeholder="Model Number"
                      value={modelNumber}
                      onChange={(event) => {
                        this.handleInputChange(event);
                      }}
                      className={
                        !isModelNumberValidated
                          ? null
                          : isModelNumberValid
                          ? "is-valid"
                          : "is-invalid"
                      }
                    ></Form.Control>
                  )}
                  <Form.Control.Feedback type="invalid">
                    Please provide a model number.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Col>
            <Col xs={12}>
              <Row>
                <Form.Check
                  type="checkbox"
                  id="hasProductSaveFlg"
                  label="Remember Product Details (Except Serial and Model)"
                  className="ms-3 mb-3"
                  checked={hasProductSaveFlg}
                  onChange={() => {
                    this.setState({
                      hasProductSaveFlg: !hasProductSaveFlg,
                    });
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              {submitWorking && (
                <React.Fragment>
                  <br />
                  <Spinner animation="border"></Spinner>
                  <br />
                  <span className="spinner-text">Processing request...</span>
                </React.Fragment>
              )}
              {!submitWorking && (
                <Fragment>
                  <Button
                    className="me-1"
                    onClick={() => this.handleSubmitClick()}
                  >
                    Submit
                  </Button>
                  <Button
                    className="ms-1"
                    onClick={() => this.props.history.push("/ServiceOrderList")}
                  >
                    Return to Dashboard
                  </Button>
                </Fragment>
              )}
            </Col>
          </Row>
          {errorMessage?.length > 0 ? (
            <Row className="mt-3">
              <Col>
                <Alert id="errorFound" variant="danger">
                  {parse(errorMessage)}
                </Alert>
              </Col>
            </Row>
          ) : null}
        </Container>
        <Modal show={showSerialNumberScanModal}>
          <Modal.Header>
            <Modal.Title>Serial Number Scanner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="UIElement">
              {!hasScannerLibraryLoaded ? <span>Loading Library...</span> : ""}
              {showScanner ? (
                <BarcodeScanner
                  fillSerialNumber={this.fillSerialNumber}
                ></BarcodeScanner>
              ) : (
                ""
              )}
            </div>
            {scannerErrorMessage?.length > 0 ? (
              <Row className="mt-3">
                <Col>
                  <Alert id="errorFound" variant="danger">
                    {parse(scannerErrorMessage)}
                  </Alert>
                </Col>
              </Row>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                this.setState({
                  showSerialNumberScanModal: false,
                  showScanner: false,
                  scannerErrorMessage: "",
                });
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    );
  }
}
