import moment from "moment";

class Auth {
  state = {
    usernameList: [
      { username: "bporter@nfuzionit.com", password: "sB792erac!" },
      { username: "tim.clark@onpointwarranty.com", password: "sB792erac!" },
      //{ username: "clarkfamily0920@gmail.com", password: "sB792erac!" },
      //{ username: "Sierra.Tune@Ferguson.com", password: "Samsung234!" },
      //{ username: "tmcgraw@therushcompanies.com", password: "Samsung123$" },
      { username: "john.lucido@onpointwarranty.com", password: "sB792erac!" },
      { username: "kenny@atlanticappliance.net", password: "Samsung765$" },
      //{ username: "jessica@kieffers.com", password: "Samsung246!" },
      //{ username: "mbean@kieffers.com", password: "Samsung369@" },
      { username: "scott.morris@onpointwarranty.com", password: "sB792erac!" },
      //{ username: "Scott.wilson@sea.samsung.com", password: "Samsung357%" },
      //{ username: "Larry.Revercomb@ferguson.com", password: "Samsung984$" },
      //{ username: "lsimmons@dividendhomes.com", password: "Samsung864!" },
      //{ username: "2022superuser@samsungportal.com", password: "Sam$ung2022!" },
      //{ username: "tim.richards@livelmc.com", password: "Sam$ung504" },
      { username: "samsung@atlanticappliance.net", password: "$amSunG852" },
    ],
  };

  login(usernameInput, passwordInput, successCB, failCB) {
    let userDetail = {};
    let userRecord = this.state.usernameList.find(function (user) {
      return user.username.toUpperCase() === usernameInput.toUpperCase();
    });

    userDetail =
      userRecord != null && Object.keys(userRecord).length > 0
        ? userRecord
        : {};

    if (Object.keys(userDetail).length > 0) {
      if (passwordInput === userDetail.password) {
        sessionStorage.setItem("email", usernameInput);
        sessionStorage.setItem("isAuthenticated", true);
        sessionStorage.setItem("loginDt", moment());
        successCB();
      } else {
        sessionStorage.clear();
        sessionStorage.setItem("isAuthenticated", false);
        failCB();
      }
    } else {
      sessionStorage.clear();
      sessionStorage.setItem("isAuthenticated", false);
      failCB();
    }
  }

  // login(usernameInput, passwordInput, successCB, failCB) {
  //   let currentPassword = "sB792erac!";

  //   if (passwordInput === currentPassword) {
  //     sessionStorage.setItem("email", usernameInput);
  //     sessionStorage.setItem("isAuthenticated", true);
  //     sessionStorage.setItem("loginDt", moment());
  //     successCB();
  //   } else {
  //     sessionStorage.clear();
  //     sessionStorage.setItem("isAuthenticated", false);
  //     failCB();
  //   }
  // }

  logout(cb) {
    sessionStorage.clear();
    sessionStorage.setItem("isAuthenticated", false);
    cb();
  }

  isAuthenticated() {
    let today = moment();
    let year = today.get("year");
    let month = today.get("month") + 1;
    let date = today.get("date");

    let loginDt = sessionStorage.getItem("loginDt");
    let loginYear = 0;
    let loginMonth = 0;
    let loginDate = 0;

    if (loginDt) {
      loginYear = moment(this.loginDt).get("year");
      loginMonth = moment(this.loginDt).get("month") + 1;
      loginDate = moment(this.loginDt).get("date");
    }

    let isAuthenticated = sessionStorage.getItem("isAuthenticated");

    if (
      isAuthenticated &&
      loginYear === year &&
      loginMonth === month &&
      loginDate === date
    ) {
      return true;
    } else {
      sessionStorage.clear();
      sessionStorage.setItem("isAuthenticated", false);
      return false;
    }
  }
}

export default new Auth();
