import React, { Component, Fragment } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import parse from "html-react-parser";
import { CircleFill, Plus, Search } from "react-bootstrap-icons";
import Header from "./header";
import { getServiceOrderList } from "../api/serviceavenger";
import moment from "moment";

export default class ServiceOrderList extends Component {
  state = {
    errorMessage: "",
    loading: true,
    serviceOrderList: [],
    userEmail: "",
  };

  componentDidMount = async () => {
    try {
      const userEmail = sessionStorage.getItem("email");
      let errorMessage = "";
      let serviceOrderListSortedByCreateDtDesc = [];
      let unsortedList = [];

      if (userEmail?.length === 0 || userEmail == null)
        errorMessage +=
          "<strong>Error encountered:</strong><br />There was an issue with the user's email.";

      const serviceOrderListResponse =
        userEmail?.length > 0 ? await getServiceOrderList(userEmail) : null;

      if (serviceOrderListResponse.status === 200) {
        if (serviceOrderListResponse.data.status !== 200) {
          const { data } = serviceOrderListResponse;
          errorMessage += `<b>Error while getting service order list:</b><br/>${data?.status} - ${data?.message}`;
          let errorDetails = data.data.details;

          if (errorDetails?.length > 0) {
            errorMessage += "<ul>";
            errorDetails.forEach((error) => {
              errorMessage += `<li>${error.code} - ${error.message}</li>`;
            });
            errorMessage += "</ul>";
          }

          this.setState({ errorMessage, loading: false });
          window.location.href = "#errorFound";
          return null;
        } else {
          unsortedList = serviceOrderListResponse.data.data;
        }
      }
      serviceOrderListSortedByCreateDtDesc =
        typeof unsortedList !== "undefined" && unsortedList?.length > 1
          ? unsortedList.sort(function (a, b) {
              if (
                new Date(a.claimDateConsumerFiled).toLocaleDateString(
                  "en-US"
                ) ===
                new Date(b.claimDateConsumerFiled).toLocaleDateString("en-US")
              ) {
                return b.serviceOrderNumber > a.serviceOrderNumber ? 1 : -1;
              }
              return (
                new Date(b.claimDateConsumerFiled) -
                new Date(a.claimDateConsumerFiled)
              );
            })
          : unsortedList;

      this.setState({
        errorMessage,
        serviceOrderList: serviceOrderListSortedByCreateDtDesc,
        userEmail,
      });
    } catch (ex) {
      this.setState({ errorMessage: ex.message, loading: false });
      throw ex;
    }
    this.setState({ loading: false });
  };

  getCircleColor(serviceOrder) {
    const daysSinceClaimCreation =
      (new Date() - new Date(serviceOrder?.claimDateConsumerFiled)) /
      (1000 * 60 * 60 * 24);
    if (daysSinceClaimCreation >= 0 && daysSinceClaimCreation < 8) {
      return "green";
    } else if (daysSinceClaimCreation > 7 && daysSinceClaimCreation < 15) {
      return "orange";
    } else if (daysSinceClaimCreation > 14) {
      return "red";
    }
  }

  getServiceOrderCardGroup() {
    return (
      <Fragment>
        <Button
          variant="primary"
          className="RoundedButton mb-3"
          onClick={() => this.props.history.push("/recall")}
        >
          <Plus /> Create
        </Button>
        <CardGroup>{this.getServiceOrderCards()}</CardGroup>
      </Fragment>
    );
  }

  getServiceOrderCards() {
    const { serviceOrderList } = this.state;
    if (serviceOrderList?.length > 0) {
      return this.state.serviceOrderList.map((serviceOrder) => {
        return (
          <Card key={serviceOrder.serviceOrderNumber}>
            <Card.Body>
              <Row>
                <Col xs={4} className="d-flex align-items-center">
                  <Button
                    className="RoundedButton"
                    variant="primary"
                    onClick={() => {
                      this.props.history.push(
                        `/ServiceOrder?serviceOrderNumber=${serviceOrder?.serviceOrderNumber}`
                      );
                    }}
                  >
                    <Search /> View
                  </Button>
                </Col>
                <Col xs={8}>
                  <Card.Title>
                    {serviceOrder?.serviceOrderNumber?.length > 0 &&
                    serviceOrder?.serviceOrderNumber != null
                      ? serviceOrder?.serviceOrderNumber
                      : null}
                  </Card.Title>
                  <Card.Subtitle>
                    <small>
                      Filed:{" "}
                      {moment
                        .utc(serviceOrder?.claimDateConsumerFiled)
                        .format("MM/DD/YYYY")}
                    </small>
                  </Card.Subtitle>
                  {serviceOrder?.address1?.length > 0 &&
                  serviceOrder?.address1 != null ? (
                    <Row>
                      <Col> {serviceOrder?.address1}</Col>
                    </Row>
                  ) : null}
                  {serviceOrder?.generalField2?.length > 0 &&
                  serviceOrder?.generalField2 != null ? (
                    <Row>
                      <Col>Lot {serviceOrder?.generalField2}</Col>
                    </Row>
                  ) : null}
                  {serviceOrder?.generalField3?.length > 0 &&
                  serviceOrder?.generalField3 != null ? (
                    <Row>
                      <Col>Block {serviceOrder?.generalField3}</Col>
                    </Row>
                  ) : null}
                  {serviceOrder?.city?.length > 0 ||
                  serviceOrder?.state?.length > 0 ||
                  serviceOrder?.zipCode?.length > 0 ? (
                    <Row>
                      <Col>
                        {serviceOrder?.city?.length > 0 &&
                        serviceOrder?.city != null
                          ? serviceOrder?.city + ","
                          : null}{" "}
                        {serviceOrder?.state?.length > 0 &&
                        serviceOrder?.state != null
                          ? serviceOrder?.state
                          : null}{" "}
                        {serviceOrder?.zipCode?.length > 0 &&
                        serviceOrder?.zipCode != null
                          ? serviceOrder?.zipCode
                          : null}
                      </Col>
                    </Row>
                  ) : null}
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="text-muted text-center">
              <CircleFill color={this.getCircleColor(serviceOrder)} /> Filed{" "}
              {parseInt(
                (new Date() - new Date(serviceOrder?.claimDateConsumerFiled)) /
                  (1000 * 60 * 60 * 24)
              )}{" "}
              day(s) ago.
            </Card.Footer>
          </Card>
        );
      });
    }
  }

  getServiceOrderTable() {
    return (
      <Table striped>
        <thead>
          <tr>
            <th className="text-center">
              <Button
                variant="primary"
                className="RoundedButton"
                size="sm"
                onClick={() => this.props.history.push("/recall")}
              >
                <Plus /> Create
              </Button>
            </th>
            <th>Ticket #</th>
            <th>Address</th>
            <th>Lot</th>
            <th>Block</th>
            <th className="text-center">Created On</th>
            <th className="text-center">Status</th>
          </tr>
        </thead>
        <tbody>{this.getServiceOrderTableBody()}</tbody>
      </Table>
    );
  }

  getServiceOrderTableBody() {
    const { serviceOrderList } = this.state;
    if (serviceOrderList?.length > 0) {
      return this.state.serviceOrderList.map((serviceOrder) => {
        return (
          <tr key={serviceOrder.serviceOrderNumber}>
            <td className="text-center">
              <Button
                className="RoundedButton"
                variant="primary"
                size="sm"
                onClick={() => {
                  this.props.history.push(
                    `/ServiceOrder?serviceOrderNumber=${serviceOrder.serviceOrderNumber}`
                  );
                }}
              >
                <Search /> View
              </Button>
            </td>
            <td>{serviceOrder?.serviceOrderNumber}</td>
            <td>{serviceOrder?.address1}</td>
            <td>{serviceOrder?.generalField2}</td>
            <td>{serviceOrder?.generalField3}</td>
            <td className="text-center">
              {moment
                .utc(serviceOrder?.claimDateConsumerFiled)
                .format("MM/DD/YYYY")}
            </td>
            <td className="text-center">
              <CircleFill color={this.getCircleColor(serviceOrder)} />
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={7}>No service orders to display.</td>
        </tr>
      );
    }
  }

  render() {
    const { errorMessage, loading } = this.state;

    return (
      <Container className="mt-3 mb-5">
        <Header history={this.props.history} title="Submitted Requests" />
        {errorMessage?.length > 0 && (
          <Row className="mt-5">
            <Col md={12}>
              <Alert variant="danger">{parse(errorMessage)}</Alert>
            </Col>
          </Row>
        )}
        {loading && (
          <Row className="mt-3">
            <Col>
              <Spinner animation="border"></Spinner>
              <br />
              <span className="spinner-text">Loading service orders...</span>
            </Col>
          </Row>
        )}
        {!loading && errorMessage?.length === 0 && (
          <Fragment>
            <Row className="d-none d-sm-block">
              <Col>{this.getServiceOrderTable()}</Col>
            </Row>
            <Row className="d-block d-sm-none">
              <Col>{this.getServiceOrderCardGroup()}</Col>
            </Row>
          </Fragment>
        )}
      </Container>
    );
  }
}
