import React, { Component } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import auth from "../helpers/auth";
import "./login.css";

export default class Login extends Component {
  state = {
    errorMessage: "",
    password: "",
    username: "",
  };

  onInputchange(event) {
    event.preventDefault();

    const { target } = event;
    const { id, value } = target;

    this.setState({
      [id]: value,
    });
  }

  render() {
    const { username, password, errorMessage } = this.state;
    return (
      <Form className="SamsungSplashScreen">
        <Container className="verticallyCentered">
          <Row className="d-flex justify-content-center">
            <Col md={6}>
              <Card>
                <Card.Body>
                  <Row className="mt-2">
                    <Col xs={9} className="mx-auto">
                      <Image src="/SamsungLogo.png" fluid />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h3 className="mt-3 text-center">Sign In</h3>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-center mb-3">
                    <Col md={12}>
                      <Form.Group controlId="username">
                        <Form.Label>
                          <h6 className="text-left">Email</h6>
                        </Form.Label>
                        <Form.Control
                          autocomplete="none"
                          type="text"
                          placeholder="Email"
                          value={username}
                          onChange={this.onInputchange.bind(this)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-center mb-3">
                    <Col md={12}>
                      <Form.Group controlId="password">
                        <Form.Label>
                          <h6>Password</h6>
                        </Form.Label>
                        <Form.Control
                          autocomplete="none"
                          type="password"
                          placeholder="Enter Password"
                          value={password}
                          onChange={this.onInputchange.bind(this)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  {errorMessage.length > 0 ? (
                    <Row className="mt-3 d-flex justify-content-center">
                      <Col md={12}>
                        <Alert id="errorFound" variant="danger">
                          {errorMessage}
                        </Alert>
                      </Col>
                    </Row>
                  ) : null}
                  <Row className="d-flex justify-content-center">
                    <Col md={12}>
                      <Button
                        className="RoundedButton"
                        onClick={() => {
                          auth.login(
                            username,
                            password,
                            () => {
                              this.props.history.push("/ServiceOrderList");
                            },
                            () => {
                              this.setState({
                                errorMessage: "Login failed.",
                              });
                            }
                          );
                        }}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                  <Row className="d-flex flex-row-reverse">
                    <Col xs={3}>
                      <Image src="/onpoint-logo.png" fluid />
                    </Col>
                  </Row>
                  <Row className="text-center mt-3">
                    <Col>Version: {process.env.REACT_APP_VERSION}</Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    );
  }
}
